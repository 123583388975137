.legend {
  width: 135px;
  background-color: #d3d3d3;
  font-size: small;
  border-radius: 10px;
}

/* Three image containers (use 25% for four, and 50% for two, etc) */
.column {
  float: left;
  width: 50%;
  padding-left: 5px;
  padding-right: 5px;
}

/* Clear floats after image containers */
.row::after {
  content: "";
  clear: both;
  display: table;
}
