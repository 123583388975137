.leaflet-container {
  height: 100%;
  width: 100%;
  z-index: 0;
}

.map-container-div {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
